<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader @change="contractChange"></contract-cascader>
          </el-form-item>
          <el-form-item>
            <el-button type="text" icon="el-icon-plus" size="mini" @click="addYear()">新增年份</el-button>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
              class="left-tree"
              v-loading="treeLoading"
              :data="treeData"
              node-key="id"
              :props="treeProps"
              ref="tree"
              highlight-current
              @node-click="treeNodeClick">
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__annualschedule">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <el-input v-model="dataForm.scheduleUnitName" placeholder="形象工程名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group style="margin-top: -3px;">
                <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                <toolbar-button role="add" v-if="$hasPermission('opm:annualschedule:save')" @click="batchAdd()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('opm:annualschedule:delete')" @click="deleteHandle()"></toolbar-button>
                <toolbar-button name="根据总计划自动生成" icon="el-icon-s-grid" v-if="$hasPermission('opm:annualschedule:generate')" @click="generateFromMaster()"></toolbar-button>
              </el-button-group>
            </el-form-item>
            <el-form-item v-if="dataForm.contractId">
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
            <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
            <el-table-column prop="scheduleUnitName" label="形象单元" min-width="150" fixed="left" show-overflow-tooltip sortable="custom"></el-table-column>
            <el-table-column prop="unitName" label="单位" width="100" header-align="center" align="center" fixed="left" show-overflow-tooltip sortable="custom"></el-table-column>
            <el-table-column prop="num" label="计划工程量" width="150" header-align="center" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="amount" label="计划金额(元)" width="150" header-align="center" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startDate" label="开始日期" width="120" header-align="center" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="endDate" label="结束日期" width="120" header-align="center" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[0]" label="一月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[1]" label="二月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[2]" label="三月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[3]" label="四月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[4]" label="五月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[5]" label="六月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[6]" label="七月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[7]" label="八月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[8]" label="九月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[9]" label="十月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[10]" label="十一月" width="70" align="right" show-overflow-tooltip></el-table-column>
            <el-table-column prop="monthDetail[11]" label="十二月" width="70" align="right" show-overflow-tooltip></el-table-column>

            <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="190">
              <template v-slot="{row}">
                <table-button role="update" v-if="$hasPermission('opm:annualschedule:update')" @click="addOrUpdateHandle(row)"></table-button>
                <table-button role="delete" v-if="$hasPermission('opm:annualschedule:delete')" @click="deleteHandle(row.id)"></table-button>
                <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:annualschedule:update'))"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 新增 / 修改 -->
          <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
          <add-or-update-year v-if="addOrUpdateYearVisible" ref="addOrUpdateYear" @refreshDataList="getTreeData" @close="closeCustomDialogHandle"></add-or-update-year>
          <batch-add v-if="batchAddVisible" ref="batchAdd" @refreshDataList="getDataList" @close="closeCustomDialogHandle"></batch-add>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './annualschedule-add-or-update'
import AddOrUpdateYear from './year-add-or-update'
import BatchAdd from './annualschedule-batch-add'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/annualSchedule/page',
        getDataListIsPage: true,
        exportURL: '/opm/annualSchedule/export',
        deleteURL: '/opm/annualSchedule',
      },
      dataForm: {
        prjId: '',
        contractId: '',
        year: '',
        scheduleUnitName: '',
      },
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'year',
      },
      treeLoading: false,
      addOrUpdateYearVisible: false,
      batchAddVisible: false,

      // 默认排序
      order:'asc',
      orderField:'start_date',
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    AddOrUpdateYear,
    BatchAdd,
  },
  methods: {
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.getTreeData()
    },
    treeNodeClick(data, node, ele) {
      this.dataForm.year = data.year
      this.getDataList()
    },
    getTreeData() {
      this.treeLoading = true
      this.$http.get(
          '/opm/year/list',
          {
            params: {
              contractId: this.dataForm.contractId,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          this.$message.error(res.msg)
          return
        }
        this.treeData = res.data.reverse()
        // 默认选中第一个节点
        if (this.treeData.length > 0) {
          this.$nextTick(() => {
            document.querySelector('.el-tree-node__content').click()
          })
        } else {
          this.dataForm.year = ''
          this.dataList = []
        }
        // 添加一个虚拟节点用于放置新增按钮
        this.treeData.push({
          id: -1,
          year: '',
          isButton: true
        })
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    addYear() {
      this.addOrUpdateYearVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateYear.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdateYear.init()
      })
    },
    closeCustomDialogHandle() {
      this.addOrUpdateYearVisible = false
      this.batchAddVisible = false
    },
    // 批量新增
    batchAdd () {
      if (!this.dataForm.year) {
        this.$message({
          message: '请首先选择左侧的年份',
          type: 'warning',
          duration: 2000
        })
      } else {
        this.batchAddVisible = true
        this.$nextTick(() => {
          this.$refs.batchAdd.dataForm.prjId = this.dataForm.prjId
          this.$refs.batchAdd.dataForm.contractId = this.dataForm.contractId
          this.$refs.batchAdd.dataForm.year = this.dataForm.year
          this.$refs.batchAdd.init()
        })
      }
    },
    // 修改
    addOrUpdateHandle (row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = row.id
        this.$refs.addOrUpdate.title = `${this.dataForm.year}年计划`
        this.$refs.addOrUpdate.scheduleUnitName = row.scheduleUnitName
        this.$refs.addOrUpdate.unitName = row.unitName
        this.$refs.addOrUpdate.init()
      })
    },
    // 从总计划引用
    generateFromMaster() {
      this.$confirm('将会按总计划的年度明细生成年计划，是否继续?', '引用总计划', {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请等待...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.get(
            '/opm/annualSchedule/generateFromMaster',
            {
              params: {
                contractId: this.dataForm.contractId,
              }
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            // 返回出错
            this.$message.error(res.msg)
            return
          }
          this.getDataList()
        }).catch(() => {
          // 查询出错
          return this.$message.error('出错了')
        }).finally(() => {
          loading.close()
        })
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 180px);
  }
</style>